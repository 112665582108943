(function() {
  var compatibilityStyle =
    '' +
    '* {opacity:1 !important;}#ieCompatibility10{position:fixed;z-index:99999;width:100%;height:100%;text-align:center;}#ieCompatibility10 table{width:100%;height:100%;margin:0;border:0;}#ieCompatibility10 td{padding:50px;text-align:center;}#ieCompatibility10 td .closeFullScreen{padding-top:50px;text-decoration:none;}h1, h2, h3, h4, h5, h6, p{margin:0 !important;padding:0 !important;}html{min-width:100%;}html,body{opacity:1 !important;visibility:visible !important;display:block !important;}html body *{display:none;}html body #ieCompatibility10{display:block !important;}html body #ieCompatibility10 table{display:table !important;*display:block !important;_display:block !important;}html body #ieCompatibility10 table tbody{display:table-row-group !important;*display:block !important;_display:block !important;}html body #ieCompatibility10 table tbody tr{display:table-row !important;*display:block !important;_display:block !important;}html body #ieCompatibility10 table tbody tr td{display:table-cell !important;*display:block !important;_display:block !important;}html body #ieCompatibility10 table tbody tr td a,html body #ieCompatibility10 table tbody tr td strong{display:inline !important;}html body #ieCompatibility10 table tbody tr td *{display:block !important;}*{background-color:#5ca1e8 !important;}#ieCompatibility10 h2,#ieCompatibility10 h3,#ieCompatibility10 p,#ieCompatibility10 a,#ieCompatibility10 a:hover,#ieCompatibility10 a:link,#ieCompatibility10 a:visited,#ieCompatibility10 strong{color:rgb(255, 255, 255) !important;}#ieCompatibility10 .ie-logo{text-align:center;}#ieCompatibility10 .ie-logo img {display:inline-block !important;}';

  const Sniffr = require('./sniffr');
  var s = new Sniffr();
  if (s.browser.name == 'IE') {
    compatibilityStyleWrapper = document.createElement('style');
    compatibilityStyleWrapper.type = 'text/css';
    if (compatibilityStyleWrapper.styleSheet) {
      compatibilityStyleWrapper.styleSheet.cssText = compatibilityStyle;
    } else {
      compatibilityStyleWrapper.appendChild(document.createTextNode(compatibilityStyle));
    }

    document.head.appendChild(compatibilityStyleWrapper);
    document.getElementById('ieCompatibility10').classList.remove('hidden');
  }
})();
