// Modified by Summit
if (!Array.prototype.map) {
  Array.prototype.map = function(callback, thisArg) {
    var mapped = [];

    for (var i = 0; i < this.length; i++) {
      mapped.push(callback.call(thisArg, this[i], i, this));
    }
    return mapped;
  };
}

if (!Array.prototype.forEach) {
  Array.prototype.forEach = function(callback, thisArg) {
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Object.keys) {
  Object.keys = function(obj) {
    var ownKeys = [];

    for (var propertyName in obj) {
      if (obj.hasOwnProperty(propertyName)) {
        ownKeys.push(propertyName);
      }
    }
    return ownKeys;
  };
}
(function(host) {

  var properties = {
    browser: [
      [/msie ([\.\_\d]+)/, "IE"],
      [/trident\/.*?rv:([\.\_\d]+)/, "IE"],
      [/firefox\/([\.\_\d]+)/, "Firefox"],
      [/chrome\/([\.\_\d]+)/, "Chrome"],
      [/version\/([\.\_\d]+).*?safari/, "Safari"],
      [/mobile safari ([\.\_\d]+)/, "Safari"],
      [/android.*?version\/([\.\_\d]+).*?safari/, "Android Browser"],
      [/crios\/([\.\_\d]+).*?safari/, "Chrome"],
      [/opera/, "Opera"],
      [/opera\/([\.\_\d]+)/, "Opera"],
      [/opera ([\.\_\d]+)/, "Opera"],
      [/opera mini.*?version\/([\.\_\d]+)/, "Opera Mini"],
      [/opios\/([a-z\.\_\d]+)/, "Opera"],
      [/blackberry/, "Blackberry"],
      [/blackberry.*?version\/([\.\_\d]+)/, "Blackberry"],
      [/bb\d+.*?version\/([\.\_\d]+)/, "Blackberry"],
      [/rim.*?version\/([\.\_\d]+)/, "Blackberry"],
      [/iceweasel\/([\.\_\d]+)/, "Iceweasel"],
      [/edge\/([\.\d]+)/, "Edge"]
    ],
    os: [
      [/linux ()([a-z\.\_\d]+)/, "Linux"],
      [/mac os x/, "Mac OS"],
      [/mac os x.*?([\.\_\d]+)/, "Mac OS"],
      [/os ([\.\_\d]+) like mac os/, "IOS"],
      [/openbsd ()([a-z\.\_\d]+)/, "Open BSD"],
      [/android/, "Android"],
      [/android ([a-z\.\_\d]+);/, "Android"],
      [/mozilla\/[a-z\.\_\d]+ \((?:mobile)|(?:tablet)/, "Firefox OS"],
      [/windows\s*(?:nt)?\s*([\.\_\d]+)/, "Windows"],
      [/windows phone.*?([\.\_\d]+)/, "Windows Phone"],
      [/windows mobile/, "Windows Mobile"],
      [/blackberry/, "Blackberry OS"],
      [/bb\d+/, "Blackberry OS"],
      [/rim.*?os\s*([\.\_\d]+)/, "Blackberry OS"]
    ],
    device: [
      [/ipad/, "iPad"],
      [/iphone/, "iPhone"],
      [/lumia/, "Lumia"],
      [/htc/, "Htc"],
      [/nexus/, "Nexus"],
      [/galaxy nexus/, "Nexus Galaxy"],
      [/nokia/, "Nokia"],
      [/ gt\-/, "Galaxy"],
      [/ sm\-/, "Galaxy"],
      [/xbox/, "XBox"],
      [/(?:bb\d+)|(?:blackberry)|(?: rim )/, "Blackberry"]
    ]
  };

  var UNKNOWN = "Unknown";

  var propertyNames = Object.keys(properties);

  function Sniffr() {
    var self = this;

    propertyNames.forEach(function(propertyName) {
      self[propertyName] = {
        name: UNKNOWN,
        version: [],
        versionString: UNKNOWN
      };
    });
  }

  function determineProperty(self, propertyName, userAgent) {
    properties[propertyName].forEach(function(propertyMatcher) {
      var propertyRegex = propertyMatcher[0];
      var propertyValue = propertyMatcher[1];

      var match = userAgent.match(propertyRegex);

      if (match) {
        self[propertyName].name = propertyValue;

        if (match[2]) {
          self[propertyName].versionString = match[2];
          self[propertyName].version = [];
        } else if (match[1]) {
          self[propertyName].versionString = match[1].replace(/_/g, ".");
          self[propertyName].version = parseVersion(match[1]);
        } else {
          self[propertyName].versionString = UNKNOWN;
          self[propertyName].version = [];
        }
      }
    });
  }

  function parseVersion(versionString) {
    return versionString.split(/[\._]/).map(function(versionPart) {
      return parseInt(versionPart);
    });
  }

  Sniffr.prototype.sniff = function(userAgentString) {
    var self = this;
    var userAgent = (userAgentString || navigator.userAgent || "").toLowerCase();

    propertyNames.forEach(function(propertyName) {
      determineProperty(self, propertyName, userAgent);
    });
  };


  if (typeof module !== 'undefined' && module.exports) {
    module.exports = Sniffr;
  } else {
    host.Sniffr = new Sniffr();
    host.Sniffr.sniff(navigator.userAgent);
  }
})(this);
